<template>
  <div>

    <v-row>

      <v-col md="2">
        <v-card>
          <v-card-title>{{ $t('stock.title') }}</v-card-title>
          <v-card-text>
            <v-list v-if="indices.length" subheader two-line>
              <index-list-item :index="index" v-for="index in indices" :key="`index-${index.id}`"/>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn right @click="addDialog = true">Ajouter</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col md="10">
        <v-card>
          <v-card-title>World Map</v-card-title>
          <v-card-text>
            <world v-if="!loading" @seiClick="viewIndex" :indices="indices"/>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <!-- edit dialog -->
    <v-dialog v-model="addDialog" max-width="600">
      <msi-index-form @done="onIndexAdded" />
    </v-dialog>

  </div>
</template>



<script>
import {Component, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import World from "@/components/charts/World";

import MsiIndexForm from "@/components/stock/MsiIndexForm";
import IndexListItem from "@/components/stock/IndexListItem";

@Component({components: {IndexListItem, MsiIndexForm, World}, mixins: [formatter]})
export default class IndexIndex extends Vue {

  indices = [];
  addDialog = false;
  loading = true;

  created() {
    this.getIndices();
  }

  onIndexAdded() {
    this.addDialog = false;
    this.getIndices();
  }

  getIndices() {
    this.$api.get(`msi-index?expand=stockExchange`).then(res => {
      this.indices = res.data;
      this.loading = false;
    });
  }

  viewIndex(index) {
    this.$router.push(`/stock/${index.id}`);
  }

}
</script>
